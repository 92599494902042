/* Icons */

.icon-pack {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    overflow: hidden;
}

.close-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/close.svg');
}

.customers-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/customers.svg');
}

.dashboard-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/dashboard.svg');
}

.invoice-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/invoice.svg');
}

.download-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/download.svg');
}

.chevron-down-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/chevron-down.svg');
}

.subscription-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/subscription.svg');
}

.billables-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/billables.svg');
}

.search-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/search.svg');
}

.add-circle-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/add-circle.svg');
}

.calendar-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/calendar.svg');
}

.settings-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/settings.svg');
}

.delete-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/delete.svg');
}

.overflow-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/overflow.svg');
}

.chevron-right-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/chevron-right.svg');
}

.reports-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/reports.svg');
}

.edit-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/edit.svg');
}

.add-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/add.svg');
}

.chevron-left-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/chevron-left.svg');
}

.products-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/products.svg');
}

.filter-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/filter.svg');
}

.subgroup-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/subgroup.svg');
}

.payments-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/payments.svg');
}

.activity-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/activity.svg');
}

.contracts-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/contracts.svg');
}

.taxes-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/taxes.svg');
}

.workflows-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/workflows.svg');
}

.integrations-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/integrations.svg');
}

.developer-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/developer.svg');
}

.sort-down-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/sort-down.svg');
}

.sort-up-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/sort-up.svg');
}

.unsorted-icon {
    @extend .icon-pack;
    background-image: url('../../public/icons/unsorted.svg');
}
