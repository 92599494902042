@import '../App.scss';

.contract-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    max-width: 1000px;
    width: 100%;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}

.contract-wrapper-updated {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    max-width: 1048px;
    width: calc(min(100%, 1048px));
    align-items: center;
    flex-grow: 1;
    margin: 24px auto;
    padding: 0 24px;
    background-color: transparent;
}

.contract-wrapper-header {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.contract-container {
    background-color: $white-color;
    border-radius: 12px;
    width: 100%;
    flex-grow: 1;
    overflow: auto;

    &.printable {
        border: 0px;
    }

    &.plain {
        background: none;
    }
}

.contract-content {
    margin: 10px auto 32px;
    width: auto;
}

.contract-header {
    padding: 15px 0px;

    [class*='col-'] {
        padding: 5px 10px 5px 10px;
    }

    .invoice-logo {
        max-width: 100%
    }

    .key {
        font-weight: bold;
    }

    .due-timer {
        font-size: 80%;
        font-style: italic;
    }

}

.contract-wrapper-footer {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.src-hellospa-components-signature-request-document__document--3NDZU {
    overflow: hidden;
}

.contract-layout {
    display: flex;
    flex-direction: column;
    max-width: 1048px;
    width: calc(min(100%, 1048px));
    align-items: center;
    flex-grow: 1;
    margin: 24px auto 0 auto;
    padding: 0 24px 24px 24px;
    background-color: $background-color;

    &.with-attachments {
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
    }
}

.contract-main {
    width: 100%;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .with-attachments & {
        flex: 3;
        min-width: 0;
    }
}

.attachments-section {
    flex: 1;
    min-width: 300px;
    max-width: 350px;
}

.attachments-container {
    background-color: $white-color;
    border-radius: 12px;
    padding: 16px;
    height: fit-content;
    position: sticky;
    top: 80px;
}

// Responsive layout
@media (max-width: 1023px) {
    .contract-layout.with-attachments {
        flex-direction: column;
        align-items: center;
    }

    .contract-main {
        width: 100%;
        min-height: 650px;
    }

    .attachments-section {
        max-width: 100%;
        margin-top: 24px;
        width: 100%;
    }

    .contract-container {
        min-height: 500px; // Adjust minimum height for smaller screens
        width: 100%;
    }

    .attachments-container {
        position: static;
    }
}