@import "../App.scss";

@layer utilities {
    th:not(.calendar-table th):not(.contract-content th):not(.thin th) {
        @apply px-4 py-2.5 text-sm text-left font-semibold text-gray-900 leading-6
    }

    td:not(.calendar-table td):not(.contract-content td):not(.thin td) {
        @apply px-4 py-2.5 text-sm text-gray-700 leading-6
    }
}

td.fr-bordered {
    border: 1px solid #888;
}

.table {
    color: $gray4-color;
    font-size: $font-size-medium;
    --bs-table-hover-bg: transparent;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    border: 1px solid $gray1-color;
    margin-bottom: 0px;

    thead {
        border: 0px;
    }

    &.no-background {
        tr {
            background: transparent;
        }
        th {
            background: transparent;
        }
    }

    tr {
        background: $white-color;
    }

    th {
        @extend .body2;
        background: $white-color;
        vertical-align: middle;
        word-break: break-word;
        padding: 3px 16px;

        height: 52px;
        border-bottom: 1px solid $gray1-color;

        &.no-stretch {
            white-space:nowrap;
            width: 1px;
        }

        &.alt {
            background: $background-color;
        }

        &.thin {
            padding-top: 3px;
            padding-bottom: 3px;
        }

        &.thin-horizontal {
            padding-left: 3px !important;
            padding-right: 3px !important;
        }
    }

    td {
        @extend .body1;
        background: transparent;
        vertical-align: middle;
        word-break: break-word;
        padding: 3px 16px;

        height: 52px;
        border-bottom: 1px solid $gray1-color;

        &.spacey {
            padding: 16px 16px;
        }

        &.no-stretch {
            white-space:nowrap;
            width: 1px;
        }

        &.thin {
            height: inherit;
            padding-top: 3px;
            padding-bottom: 3px;
        }

        &.thin-horizontal {
            padding-left: 3px !important;
            padding-right: 3px !important;
        }

        &.remove-padding {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    tbody {
        border: 0px;
    }

    p {
        margin: 0px !important;
    }

    tr.linked {
        cursor: pointer;
    }

    tr.thin {
        td {
            height: inherit;
            padding-top: 3px;
            padding-bottom: 3px;
        }

        th {
            height: inherit;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    tr.spacey {
        td, th {
            padding: 16px 16px;
        }
    }

    tr.thin-horizontal {
        td {
            height: inherit;
            padding-left: 3px !important;
            padding-right: 3px !important;
        }

        th {
            height: inherit;
            padding-left: 3px !important;
            padding-right: 3px !important;
        }
    }

    tr.borderless {
        border: 0px !important;

        td {
            border: 0px !important;
        }
    }

    &>:not(:first-child) {
        border: 0px;
    }

    tbody tr td {
        border-top: 0px;
    }

    .form-group {
        margin-bottom: 0px;
    }
}

table {
    tr.line-item {
        &:first-child {
            td {
                padding-top: 16px;
            }
        }
    }
}

.table-hover>tbody>tr:not(.invoice-collapsible-row-data):hover>* {
    background-color: $background-color;
}

.table-borderless {
    border: 0px;

    td {
        border: 0px !important;
    }
}

.de-highlighted {
    td:not(.actions) {
        opacity: 0.5;
    }
}

.table-header {
    background: $white-color;
    width: 100%;
    height: 52px;
    border-top: 1px solid $gray1-color;
    border-left: 1px solid $gray1-color;
    border-right: 1px solid $gray1-color;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .table-header-actions {
        padding: 0px 16px;
        justify-content: center;
        display: flex;
        flex-direction: column;

        &:empty {
            padding: 0px 16px 0px 0px;
        }

        &.thin {
            padding: 0px 0px;
        }
    }

    .form-group {
        margin-bottom: 0px;
    }

    .dropdown .dropdown-menu {
        max-height: 1000px;
    }

    .table-filter {
        position: relative;

        .dropdown-toggle {
            .table-filter-count {
                display: flex;
                align-items: center;
                padding: 0px 6px 0px 12px;
                border-left: 1px solid $primary-color;
            }

            &.show {
                .table-filter-count {
                    border-left: 1px solid $white-color;
                }
            }
        }

        .table-filter-menu {
            width: 264px;
            overflow: visible;

            .table-filter-menu-item {
                display: flex;
                flex-direction: column;
                border-bottom: 0px solid $gray1-color;

                .table-filter-menu-item-header {
                    padding: 8px 16px 12px;
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                    cursor: pointer;
                    display: none;
                }

                .table-filter-menu-item-body {
                    padding: 8px 16px;
                    display: flex;
                    flex-direction: column;
                }

                .table-filter-menu-item-action {
                    @extend .chevron-down-icon;
                    &.open {
                        @extend .chevron-left-icon;
                    }
                }
            }

            .table-filter-menu-body {
                padding-bottom: 16px;
            }

            .table-filter-menu-header {
                padding: 16px;
                display: flex;
                flex-direction: row;
                gap: 12px;
            }

            .table-filter-menu-footer {
                padding: 16px;
                background: $background-color;
                display: flex;
                gap: 12px;
                flex-direction: row;
                justify-content: end;
            }
        }
    }
}

.sticky-col {
    position: sticky;
    left: -1px;
    background-color: $background-color!important;
}

.table-content table:has(thead:not(:empty) ~ tbody:empty) {
    overflow: hidden;
}