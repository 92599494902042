@import '../App.scss';

.invoice-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
    align-items: center;
    padding-top: 24px;

    &.full {
        max-width: 100%;
    }
}

.content-container {
    .invoice-wrapper {
        padding-top: 0px;
    }
}

.invoice-wrapper-header {
    padding: 16px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.invoice-wrapper-header-updated {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.invoice-container {
    border: 1px solid $gray2-color;
    background-color: $white-color;
    border-radius: 4px;
    width: 100%;
    padding: 35px;
    overflow: hidden;

    &.printable {
        border: 0px;
        padding: 5px;

        .small-product-logo {
            display: none;
        }
    }

    .void {
        color: red;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../public/images/void-background.png');
        pointer-events: none;
    }
}

.invoice-header {
    padding: 0px 0px 20px 0px;

    [class*='col-'] {
        padding: 5px 10px 5px 10px;
    }

    .invoice-logo {
        max-width: 350px;
        max-height: 150px;
    }

    .key {
        font-weight: bold;
    }

    .due-timer {
        font-size: 80%;
        font-style: italic;
    }

}

.invoice-row {
    width: 100%;
}

.invoice-collapsible-row {
    cursor: pointer;

    &.no-hover {
        cursor: inherit;
    }
}

.invoice-collapsible-row-data {
    background: green;
    height: auto;

    &.show {
        height: auto;

        tr {
            break-inside: avoid;
        }
    }

    &.hide {
        height: 0;
    }
}

.invoice-wrapper-footer {
    padding: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}
