@tailwind base;
@tailwind components;
@tailwind utilities;
@import './css/icons.scss';

// Chocolate: #D2691E
// Olive drab2: #698B22
// Peach: #de7e5d
// soft pink: #ff6150
// something: #16acb1

// $primary-color: #008769;
// $primary-color: #bd3a46;
// $primary-color: #FF6C00;
// $primary-color: #932f42;
// $primary-color: #e75163;

// $primary-color: #ff5a5a;
$primary-text-color: white;

$content-background: white;
$content-text-color: #333333;
$content-highlight-color: #e0e0e0;
$content-highlight-text-color: #333333;

$content-active-color: lightgray;
$content-active-text-color: #626262;

$header-height: 55px;

$font-size-medium: 14px;
$font-size-medium-large: 16px;

// Colors from design
$primary-color: #7F56D9;
// $primary-color-updated: #7F56D9;
$primary-color-updated-light: #9E77ED;
$primary-color-updated-dark: #6941C6;
$primary-light-color: #E9E9FB;
$primary-light2-color: #E9E9FB40;
$primary-dark-color: #463CC9;
$red-color: #D32F2F;
$red-color-updated: #D92D20;
$red-color-updated-dark: #B42318;
$red-light-color: #FFEBEE;
$positive-color: #006B6A;
$positive-light-color: #D9E9E9;
$warning-color: #E47A18;
$warning-light-color: #FAF1D9;
$navy-color: #2E405A;
$aegean-color: #4777C1;
$indigo-color: #314992;
$tiger-color: #FF9800;

$black-color: #1F2338;
$gray4-color: #474758;
$gray3-color: #807E8C;
$gray2-color: #C4C4C4;
$gray1-color: #E1E1E9;
$gray0-color: #FAFAFA;
$background-color: #FAF8F4;
$white-color: #FFFFFF;
$dark-gray-color: #33333C;
$dark-gray-color-2: #181D27;

// left nav
$leftnav-collapsed-width: 76px;
$leftnav-uncollapsed-width: 296px;
$leftnav-transition-duration: 400ms;
$leftnav-bg-color: #0A0D12;
$leftnav-active-bg-color: #252B37;
$leftnav-active-text-color: #E9EAEB;
$leftnav-profile-border-color: #535862;

// button
$button-disabled-border-color: #E9EAEB;
$button-disabled-bg-color: #F5F5F5;
$button-disabled-text-color: #A4A7AE;

// border
$border-color: #D5D7DA;

// dropdown
$dropdown-bg-color: #F3F4F6;
$dropdown-border-color: #E9EAEB;

:export {
    indigoColor: $indigo-color;
    navyColor: $navy-color;
    aegeanColor: $aegean-color;
    tigerColor: $tiger-color;
    primaryColor: $primary-color;
    primaryLightColor: $primary-light-color;
    primaryDarkColor: $primary-dark-color;
    warningColor: $warning-color;
    warningLightColor: $warning-light-color;
    positiveLightColor: $positive-light-color;
    positiveColor: $positive-color;
    redLightColor: $red-light-color;
    redColor: $red-color;
    dangerColor: $red-color;
    gray4Color: $gray4-color;
    gray3Color: $gray3-color;
    gray2Color: $gray2-color;
    gray1Color: $gray1-color;
    blackColor: $black-color;
    whiteColor: $white-color;
    darkGrayColor: $dark-gray-color;
    backgroundColor: $background-color;
    leftnavBackgroundColor: $leftnav-bg-color;
}

html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: "Inter", -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu", sans-serif;
    background: $white-color;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Inter", -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu", sans-serif;
  //font-family: 'Lato', sans-serif;
  background: $background-color;
}

#root, [data-reactroot] {
    height: 100%;
}

.App {
  height: 100%;
  background: $background-color;
}

h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 800;
    //letter-spacing: 0.25px;
}

h2 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 400;
    //letter-spacing: 0.25px;
    margin-bottom: 0px;
}

h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    //letter-spacing: 0.25px;
    margin-bottom: 0px;
}

h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    //letter-spacing: 0.25px;
    margin-bottom: 0px;
}

h5 {
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
   //letter-spacing: 0.25px;
    margin: 0px;
}

.subtitle {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    //letter-spacing: 0.5px;
}

.body1 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
   //letter-spacing: 0.5px;
}

.body2 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
   //letter-spacing: 0.5px;
}

.button-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    //letter-spacing: 0.75px;
}

.label-text {
    font-size: 12px;
    line-height: 16px;
    //letter-spacing: 0.4px;
    font-weight: 600;
}

.caption {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    //letter-spacing: 0.5px;
}

.italic {
    font-style: italic;
}

.primary {
    color: $primary-color !important;
}

.gray1 {
    color: $gray1-color !important;
}

.gray2 {
    color: $gray2-color !important;
}

.gray3 {
    color: $gray3-color;
}

.gray4 {
    color: $gray4-color;
}

.error {
    color: $red-color;
}

.danger-color {
    color: rgba($red-color, 0.9);
}

.success-color {
    color: $positive-color;
}

.warning-color {
    color: $warning-color;
}

.bg-primary-600 {
    background-color: $primary-color
}

@layer utilities{
    .bg-primary-100 {
        background-color: $primary-light2-color
    }

    .bg-primary-500 {
        background-color: rgba($primary-color, 0.85);
    }
}

.flex-grow-1 {
    min-width: 0px;
}

// .row {
//     margin-left: 0px;
//     margin-right: 0px;
// }
//
// .row > [class*='col-'] {
//     padding-left: 0px;
//     padding-right: 0px;
// }
//
.half {
    width: 50%;
}

p {
    @extend .body1;
    &.skinny {
        margin: 0px;
    }
}

hr {
  background-color: $dark-gray-color;
}

.beamer_defaultBeamerSelector {
  display: none;
}

.beamer_icon.active {
    position: relative !important;
}

a {
  color: #3a8fc8;
  text-decoration: none;
}

a:hover {
  color: #48b0f7;
}

a.plain {
    color: #626262;
}

// .gap-2 {
//     gap: 2px;
// }
//
// .gap-6 {
//     gap: 6px;
// }
//
// .gap-12 {
//     gap: 12px;
// }
//
// .gap-32 {
//     gap: 32px;
// }

.half-flex {
    flex: 1;
    flex-basis: auto;
}

// h6 {
//     margin: 10px 0;
//     font-size: $font-size-medium;
// }
//
// h2 {
//     font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
//     &.title {
//         font-weight: 300;
//     }
// }
//
// h3 {
//     margin: 0px 0px 10px 0px;
// }
//
// h5 {
//     margin: 0px;
// }

.fa-primary {
    color: $primary-color;
}

.hide {
    display: none !important;
}

.showprint {
    display: none;
}

.center {
    text-align: center;
}

.centered-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.profile-icon {
    @extend .body1;
    color: $gray4-color;
    height: 40px;
    width: 40px;
    min-width: 40px;
    background: $primary-light-color;
    border-radius: 23px;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header {
    padding: 6px 24px;
    color: $gray2-color;
    background: $white-color;

    display: none;
    flex-direction: row;

    .logo {
        height: 40px;
    }
}

.header-toolbar {
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    gap: 12px;

    &.expandable {
        flex-grow: 1;
    }

    .profile-icon {
        background: $primary-color;
    }

    .profile-title {
        @extend .body2;
        color: $gray4-color;
    }

    .profile-subtitle {
        @extend .body1;
        min-height: 22px;
        color: $gray3-color;
    }
}

.header-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-wrapper {
    padding: 0px;
    position: absolute;

    background: #f7f8fa;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.right-wrapper {
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0px;

    &.updated-nav {
        margin-left: $leftnav-uncollapsed-width;
        transition: margin-left $leftnav-transition-duration ease;

        &.right-wrapper-nav-collapsed {
            margin-left: $leftnav-collapsed-width;
        }
    }
}

@media (max-width: 1023px) {
    .right-wrapper {
        margin-left: 0 !important;
    }
}

.content-wrapper {
    background: $background-color;
    padding: 24px 32px;
    max-width: 100%;
    flex-grow: 1;

    overflow: auto;
}

.title-update-page {
    color: $dark-gray-color-2;
    font-size: 26px;
}

.title-update {
    color: $dark-gray-color-2;
    font-size: 22px;
}

.title-update-md {
    color: $dark-gray-color-2;
    font-size: 20px;
}

.content-container {
    box-sizing: border-box;
    background: $background-color;

    &.full {
        height: 100%;
    }

    &.alt {
        background: $white-color;
    }

    .content-header {
        min-height: 40px;
        margin-bottom: 18px;

        .logo {
            height: 50px;
            width: 50px;

            img {
                border-radius: 4px;
                height: 100%;
                width: 100%;
            }
        }
    }
}

.content-section {
    border-radius: 10px;
    margin-bottom: 25px;

    .content-section-header {
        padding: 12px 0px;

        .title {
            @extend h3;
            color: $black-color;
            align-items: center;
        }

        .actions {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
        }
    }

    .content-section-empty-placeholder {
        padding: 10px 0px;
        font-size: $font-size-medium-large;
    }
}

.notification {
    position: absolute;
    z-index: 100;
    font-size: $font-size-medium;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 10px;
    font-weight: bold;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    transition: top 0.2s ease-in-out;

    .alert {
        margin: 0px;
        padding: 10px;
        font-weight: bold;
        border-radius: 4px;;
    }

    &.ashow {
        top: 0px;
    }

    &.ahide {
        top: -60px;
    }
}

.notification-modal {
    position: absolute;
    z-index: 9;
    top: -50px;
    left: 0px;
    border-radius: 0;
    width: 100%;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    transition: top 0.2s ease-in-out;

    .alert {
        margin: 0px;
        background: #fef6dd;
        color: #957d32;
        border-radius: 0;
        font-weight: bold;
    }

    &.ashow {
        top: 0px;
    }

    &.ahide {
        top: -50px;
    }
}

.Toastify__progress-bar--success {
    background-color: #006B6Aa0;
}
.Toastify__progress-bar--warning {
    background-color: #E6B941;
}
.Toastify__progress-bar--error {
    background-color: #CE6A5D;
}

.toast-container {
    margin-top: 60px;
    z-index: 100;

    .toast-body {
        background-color: $background-color;
    }
}

.dropdown {
    padding-left: 5px;

    &.form {
        padding-left: 0px;
    }

    &.inline {
        display: inline-block;
        margin-right: 5px;
    }

    &.disabled {
        pointer-events: none;
    }

    a {
        color: #626262;
        text-decoration: none;
    }

    :not(.form-group)>input {
        border: 1px solid rgba(0, 0, 0, 0.07) !important;
        padding: 10px !important;
    }

    .dropdown-menu {
        @extend .body1;
        background: $white-color;
        white-space: nowrap;
        border: 1px solid $gray2-color;
        padding: 0px;
        min-width: 90px;
        max-height: 300px;
        border-radius: 4px;

        ul.list-unstyled {
            margin-bottom: 0px;
            overflow: auto;
            max-height: 240px;
            border-radius: 4px;
        }

        li {

            a {
                display: block;
                padding: 7px 16px;
            }

            &:hover {
                background-color: $background-color;
            }
        }

        .dropdown-item {
            @extend .body1;
            color: $gray4-color;
            background: $white-color;
            padding: 7px 16px;
            display: flex;
            gap: 6px;
            align-items: center;

            &:hover, &.active {
                background: $background-color;
            }

            &.disabled {
                color: $gray2-color;
            }
        }
    }

    .dropdown-toggle {
        &.alt {
            &.show {
                background-color: $primary-color;
            }

            &:hover, &:focus, &:active, &:active:focus, &.show:hover, &.show:focus, &.show:active, &.show:active:focus {
                border: 1px solid $primary-color;
                box-shadow: none;
                opacity: 1;
            }

            &.plain {
                &::after {
                    display: none;
                }
            }
        }

        &.no-caret {
            &::after {
                display: none;
            }
        }
    }
}

.profile-border-color {
    border-color: $leftnav-profile-border-color !important;
}
.profile-icon-size {
    min-width: 40px !important;
    min-height: 40px !important;
}
.profile-name-color {
    color: $gray0-color;
}

.left-nav {
    overflow: scroll;
    width: $leftnav-uncollapsed-width;
    height: 100%;
    z-index: 30;
    background: $leftnav-bg-color;
    color: $white-color;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width $leftnav-transition-duration ease;

    &.left-nav-collapsed {
        width: $leftnav-collapsed-width;

        &.left-nav-expanded {
            width: $leftnav-uncollapsed-width;
        }
    }

    &.super {
        background: $red-color;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .profile-container {
        background-color: $dark-gray-color-2;
        padding: 12px 12px 12px 12px;
        border-color: $leftnav-profile-border-color !important;
        transition: all $leftnav-transition-duration ease;
    }

    .nav-footer {
        position: sticky;
        bottom: 0px;
        background-color: $leftnav-bg-color;
        padding: 16px 16px 16px 16px;
        border-top: 1px solid $leftnav-profile-border-color;
        transition: all $leftnav-transition-duration ease;

        &.nav-footer-collapsed {
            .profile-container {
                background-color: transparent;
                padding: 12px 12px 12px 1px;
                border-color: transparent !important;
            }
        }
    }

    .nav-header {
        position: sticky;
        top: 0;
        background-color: $leftnav-bg-color;
        padding: 20px 16px 12px 16px;
        display: flex;
        gap: 12px;
        height: 69px;
        width: 100%;

        .nav-header-logo {
            flex-grow: 1;
            flex-shrink: 0;
            width: 137px;
            height: 41px;
            min-height: 41px;
            background-image: url("../public/logo-small.svg");
            border-radius: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1;
            margin-left: -3px;
        }

        .hide-maple-block {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 48px;
            background-color: $leftnav-bg-color;
            z-index: 1;
            opacity: 0;
            transition: opacity $leftnav-transition-duration ease;

            &.hide-maple-block-collapsed {
                opacity: 1;
            }
        }

        .nav-title {
            @extend h3;
            font-size: 30px;
            font-weight: 400;
            text-transform: capitalize;
            color: $white-color;
            display: flex;
            align-items: center;
        }
    }

    .nav-items {
        margin: 12px 16px 16px 16px;
        display: flex;
        gap: 2px;
        flex-direction: column;

        .nav-items-link {
            text-decoration: none;
            color: $white-color;
            text-wrap: nowrap;
        }
        .parent-menu {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .sub-items {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .nav-item-container {
            display: flex;
            flex-direction: column;
            padding: 8px 12px;
            border-radius: 6px;
            cursor: pointer;

            &.open {
                color: $leftnav-active-text-color;
                background-color: $leftnav-active-bg-color;
            }
            &:hover, &.active {
                color: $leftnav-active-text-color;
                background-color: $leftnav-active-bg-color;
            }
        }

        .nav-item {
            height: 24px;
            display: flex;
            gap: 8px;

            .nav-item-logo {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                min-width: 20px;
            }
            .nav-title {
                flex-grow: 1;
                opacity: 100;
                transition: opacity 400ms ease;

                &.nav-title-collapsed {
                    opacity: 0;
                }
            }
            .nav-item-chevron {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .nav-title1 {
        float: left;
        height: 100%;
        padding-left: 20px;
        display: table;
        width: 170px;

        span {
            display: table-cell;
            vertical-align: middle;
        }
    }

  .sub-menu {
    height: 0;
    display: none;

    &.show {
        height: initial;
        display: initial;
        transition-duration: 500ms;
        transition-property: height;
    }

    .nav-title {
        padding-left: 30px;

        span {
            font-size: $font-size-medium;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 160px;
            display: block;
        }
    }

    li {
        height: 27px;
        padding: 3px 0;
    }

    .icon-thumbnail i {
        font-size: $font-size-medium-large;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    padding: 13px 0;
    border-left: 1px solid #dddddd;
  }

  li {
    list-style: none;
    height: 30px;
    padding: 3px 0;
    margin-left: -2px;
    border-left: 2px solid transparent;
    font-size: $font-size-medium-large;

    &.left-nav-menu-drop {
        cursor: pointer;
    }

    &:hover {
        background: transparent;
        color: $content-highlight-text-color;
        border-left: 2px solid $primary-color;
    }

    &.active {
        background: transparent;
        color: $primary-color;
        border-left: 2px solid $primary-color;
    }

    a {
        width: 100%;
        height: 100%;
        color: inherit;
        text-decoration: none;
    }

    a:hover {
        color: inherit;
    }

    .arrow {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        font-size: $font-size-medium-large;
        font-family: FontAwesome;
    }

    .arrow::before {
        content: "\f104";
    }

    &.show {
        .arrow::before {
            content: "\f107";
        }
    }
  }
}

.metrics-menu {
    width: 180px;
    min-width: 180px;

    .nav-items {
        margin-bottom: 16px;
        display: flex;
        gap: 2px;
        flex-direction: column;

        .nav-item {
            @extend .body1;
            height: 26px;
            display: flex;
            padding-left: 8px;
            padding-right: 8px;
            gap: 12px;
            cursor: pointer;
            color: $gray3-color;
            border: 1px solid transparent;
            align-items: center;

            &:hover, &.active {
                color: $gray4-color;
                border: 1px solid $primary-light-color;
                background: $primary-light-color;
                border-radius: 4px;
            }

            .nav-title {
                flex-grow: 1;
                white-space: nowrap;
            }
        }

        .nav-group-header {
            @extend .body2;
            margin-top: 12px;
            margin-bottom: 4px;
        }
    }
}

.breakout-table-wrapper {
    width: 100%;
    overflow: auto;
}

.fa {
    &.fa-1-5x {
        font-size: 21px;
    }

    &.fa-small {
        font-size: $font-size-medium;
    }

    &.fa-large {
        font-size: $font-size-medium-large;
    }

    &.fa-c-success {
        color: #10cfbd;
    }

    &.fa-c-info {
        color: #a0a0a0;
    }

    &.fa-c-danger {
        color: #f55753 !important;
    }

    &.fa-unselected {
        opacity: 0.3;
    }
}

.panel-body {
    padding: 20px 15px;
    min-width: 250px;
}

.container-fluid {
    &.full {
        height: 100%;
        display: flex;
        min-height: 0px;
        flex-direction: column;
        flex-flow: column;
    }
}

.calendar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.screen-wrapper {
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.dropdown-menu {
    .nav-wrapper {
        border-bottom: 1px solid #626262;
    }

    hr {
        margin-top: 3px;
        margin-bottom: 3px;
    }
}

.tab-header {
    background: #fafafa;
    display: flex;
    padding: 10px 40px;
    border-bottom: 1px solid #e0e0e0;

    .left {
        flex-grow: 1;
    }

    .right {
        flex-basis: max-content;
    }

    .nav-wrapper {
        background: transparent;
        z-index: 1;

        .nav-link {
            color: $content-text-color;
            border-radius: 20px;
            font-size: $font-size-medium;
            padding: 2px 7px;
            margin-right: 5px;

            &:hover, &:active, &:focus, &:focus:active {
                opacity: 1;
                background-color: $content-highlight-color;
                color: $content-highlight-text-color;
            }

            &.active {
                color: $primary-text-color;
                background-color: $primary-color;
                margin-bottom: -1px;
                border-left: 1px solid #00000012;
                border-right: 1px solid #00000012;
            }

            &:focus-visible {
                outline: none;
            }
        }
    }
}


.section-nav {
    display: flex;

    .left {
        flex-grow: 1;
    }

    .right {
        flex-basis: max-content;
    }

    .nav-wrapper {
        background: transparent;
        z-index: 1;

        .nav-link {
            @extend .body2;
            color: $gray3-color;
            background-color: transparent;
            padding: 10px 16px;
            margin: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid transparent;
            gap: 6px;

            &:hover, &:active, &:focus, &:focus:active {
                opacity: 1;
            }

            &.active {
                color: $black-color;
                margin-bottom: -1px;
                border-bottom: 2px solid $primary-color;
            }

            &:focus-visible {
                outline: none;
            }

            .nav-counter {
                @extend .button-text;
                background-color: $gray1-color;
                color: $gray4-color;
                border-radius: 12px;
                width: 24px;
                height: 24px;
                text-align: center;

                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}


.content-box {
    border: 1px solid $gray1-color;
    background: white;
    margin-bottom: 20px;

    &.full {
        flex-grow: 1;
        margin-bottom: 0px;
    }

    .content-header {
        padding: 20px 20px 7px 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-medium;
    }

    .content-body {
        padding: 20px 20px 20px 20px;
        font-size: $font-size-medium;
        color: $gray4-color;
        word-break: break-word;

        &.full {
            height: 100%
        }

        &.scrollable {
            overflow: scroll;

            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .content-header + .content-body {
        padding-top: 0px;
    }
}

.content-body {
    @extend .body1;
    &.full {
        height: 100%
    }
}

pre {
    padding: 20px;
    background-color: #e6e6e6;
    border-radius: 3px;

    code {
        background: #f0f0f0;
        color: #c64643;
    }
}

.alert {
    @extend .body1;
    padding: 10px;
    border-width: 0px;
    word-break: break-word;

    p {
        margin: 0 !important;
    }

    &.skinny {
        margin-bottom: 0px;
    }
}

.alert-success {
    background-color: #ECFDF3;
    color: #067647;
    border: 1px solid #ABEFC6;
    border-radius: 12px;
}

.alert-info {
    background-color: #daeffd;
    color: #2b6a94;
    border-color: #2b6a94;
}

.alert-danger {
    border: 1px solid $red-color-updated;
    background-color: #FFFBFA;
    color: $dark-gray-color-2;
    border-radius: 12px;
}

.alert-warning {
    border: 1px solid $warning-color;
    background-color: #FBF4DD;
    color: $dark-gray-color-2;
    border-radius: 12px;
}

.btn {
    @extend .button-text;
    height: 36px;
    padding: 8px 14px;
    color: #626262;
    box-shadow: none;
    border: 0px;
    border-radius: 8px;

    &.btn-sm {
        height: 24px;
        padding: 2px 6px;;
    }

    &.dropdown-toggle {
        padding: 4px 4px;
    }
}

.btn:hover, .btn:focus, .btn:active, .btn:active:focus {
    color: #333;
    border: 0px;
    box-shadow: none;
}

.btn:disabled {
    border: 1px solid $button-disabled-border-color;
    color: $button-disabled-text-color;
    background-color: $button-disabled-bg-color;
}

.btn-outline:disabled {
    color: $gray3-color;
    background-color: $white-color;
    border: 1px solid $gray1-color;
}

.btn-text:disabled {
    color: $gray3-color;
    background-color: transparent;
}

.btn-primary {
    color: $white-color;
    background-color: $primary-color;
    opacity: 1 !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:active:focus {
    @extend .btn-primary;
    background-color: $primary-color-updated-dark;
}

.btn-primary:focus {
    @extend .btn-primary;
    outline: 1px solid $primary-color-updated-dark;
    outline-offset: 2px;
}

.btn-text-primary {
    color: $primary-color;
    background-color: transparent;
    opacity: 1 !important;
}

.btn-text-primary:hover, .btn-text-primary:active, .btn-text-primary:active:focus {
    @extend .btn-text-primary;
    color: $primary-color-updated-dark;
}

.btn-text-primary:focus {
    @extend .btn-text-primary;
    outline: 1px solid $primary-color-updated-dark;
    outline-offset: 2px;
}

.btn-danger {
    color: $white-color;
    background-color: $red-color-updated;
    opacity: 1 !important;
}

.btn-danger:hover, .btn-danger:active, .btn-danger:active:focus {
    @extend .btn-danger;
    background-color: $red-color-updated-dark;
}

.btn-danger:focus {
    @extend .btn-danger;
    outline: 1px solid $red-color-updated;
    outline-offset: 2px;
}

.btn-outline-danger {
    color: $red-color;
    background-color: $white-color;
    border: 1px solid $red-color;
}

.btn-outline-danger:hover, .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:active:focus {
    @extend .btn-outline-danger;
    opacity: 0.7;
}

.btn-outline-danger-less {
    color: $red-color-updated;
    background-color: $white-color;
    border: 1px solid $border-color;
}

.btn-outline-danger-less:hover, .btn-outline-danger-less:active, .btn-outline-danger-less:active:focus {
    @extend .btn-outline-danger-less;
    color: $red-color-updated-dark;
}

.btn-outline-danger-less:focus {
    @extend .btn-outline-danger-less;
    color: $red-color-updated-dark;
}

.btn-text-danger {
    color: $red-color-updated;
    background-color: transparent;
    opacity: 1 !important;
}

.btn-text-danger:hover, .btn-text-danger:active, .btn-text-danger:active:focus {
    @extend .btn-text-danger;
    color: $red-color-updated-dark;
}

.btn-text-danger:focus {
    @extend .btn-text-danger;
    outline: 1px solid $red-color-updated;
    outline-offset: 2px;
}

.btn-outline-primary {
    color: $primary-color;
    background: $white-color;
    border: 1px solid $primary-color;
    opacity: 1 !important;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:active:focus {
    @extend .btn-outline-primary;
    background-color: $gray0-color !important;
}

.btn-outline-primary:focus {
    @extend .btn-outline-primary;
    outline: 1px solid $primary-color;
    outline-offset: 2px;
}

.btn-text-outline {
    color: #414651;
    background: $white-color;
    border: 1px solid $border-color;
}

.btn-text-outline:hover, .btn-text-outlineupdated:active, .btn-text-outline:active:focus {
    @extend .btn-text-outline;
    color: $leftnav-active-bg-color;
    background-color: $gray0-color;
}

.btn-text-outline:focus {
    @extend .btn-text-outline;
    outline: 1px solid $primary-color;
    outline-offset: 2px;
}

.btn-nolink {
    font-size: $font-size-medium;
    color: #626262;
    margin-right: 3px;
    box-shadow: none;
    padding: 7px 17px;
    display: inline-block;
    border: 1px solid #f0f0f0;
    background-color: white;
    white-space: nowrap;
    cursor: default !important;
}

.btn-nolink:hover, .btn-nolink:focus, .btn-nolink:active, .btn-nolink:active:focus {
    color: #626262;
    box-shadow: none;
}

.btn-link {
    padding: 7px 13px;
    border: none;
    background-color: transparent;
    text-decoration: none;

    &.skinny {
        padding: 7px 7px;
    }
}

.btn-alink {
  color: #3a8fc8 !important;
  text-decoration: none;

  &.skinny {
    margin: 0px;
    padding: 0px 4px;
    border: 0px;
    margin-top: 0px;
  }
}

.btn-alink:hover {
  color: #48b0f7 !important;
}

.btn-success {
    color: #fff;
    background-color: #10cfbd;
    border-color: #10cfbd;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus {
    color: #fff;
    background-color: #40d9ca;
    border-color: #40d9ca;
}

.btn-select, .btn-select:disabled {
    border: 1px solid #f0f0f0;
    background: none;
    white-space: nowrap;
    background: $white-color;
}

.btn-select:hover, .btn-select:focus, .btn-select:active, .btn-select:active:focus {
    border: 1px solid #62626240;
    background-color: #e6e6e680;
    box-shadow: none;
    color: inherit;
}

.btn-complete {
    width: 100%;
}

a > .label {
    color: inherit;
}

.primary-link {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        color: $primary-color-updated-dark;
    }
}

.label {
    @extend .label-text;
    padding: 6px 10px;
    border-radius: 4px;
    white-space: nowrap;
}

.label-warning {
    background-color: #E6B94133 !important;
    color: #C56710;
}

.label-success {
    background-color: #006B6A26 !important;
    color: #006B6A;
}

.label-danger {
    background-color: #CE6A5D33 !important;
    color: #CE6A5D;
}

.label-info {
    background-color: $gray1-color;
    color: $gray3-color;
}

.label-neutral {
    background-color: $primary-light-color !important;
    color: $primary-dark-color;
}
input[type=radio] {
    --tw-ring-color: transparent;
}
input[type=radio]:checked {
    --tw-ring-color: transparent;
}
input[type=radio]:checked + label:target {
    --tw-ring-color: transparent;
}
input[type=radio]:checked + label:before {
    --tw-ring-color: transparent;
}
input[type=radio]:checked + label:after {
    --tw-ring-color: transparent;
}

input[type=checkbox] {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked {
    --tw-ring-color: transparent;
}
input[type=checkbox]:not(:checked) {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked + label:focus-visible {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked + label:focus-within {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked + label:target {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked + label:before {
    --tw-ring-color: transparent;
}
input[type=checkbox]:checked + label:after {
    --tw-ring-color: transparent;
}
.checkbox {
    padding-left: 0px;
    padding-top: 0px;
    min-height: 20px;

    &.tall {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 23px !important;
    margin-right: 0px;
    min-height: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    white-space: nowrap;
    color: #626262;
}

.checkbox input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.checkbox label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 5px;
    position: absolute;
    left: 1px;
    background-color: #fff;
    border: 2px solid #d0d0d0;
    top: 1px;
    border-radius: 3px;
}

.checkbox label::after {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 4px;
    top: -1px;
    font-size: 13px;
    border-radius: 3px;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox.checkbox-circle label:after {
  border-radius: 99px;
}
.checkbox.checkbox-circle label:before {
  border-radius: 99px;
}
.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px;
}
.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff;
}
.checkbox input[type="checkbox"]:focus + label {
  color: #2c2c2c;
}
.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox][disabled] + label:before {
  background-color: #eceff3;
}
.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important;
}
.checkbox.right label:before {
  right: -35px;
  left: auto;
}
.checkbox.right input[type=checkbox]:checked + label {
  position: relative;
}
.checkbox.right input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  position: absolute;
  right: -27px;
  left: auto;
}
body.rtl .checkbox label::after {
  left: -1.2px;
}
.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: $primary-color;
  background: $primary-color;
}
.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #6d5cae;
}
.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #48b0f7;
}
.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #f8d053;
}
.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f55753;
}
.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #3b4752;
}
.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #fff;
  top: -1px;
}
.ui-sortable-helper {
    display: table;
}

.quickview {
    position: absolute;
    width: 324px;
    height: 100%;
    background: white;
    z-index: 1003;
    top: 0;
    right: 0;
    margin-right: -324px;
    transition-duration: 300ms;
    transition-property: margin-right;
    border-left: 1px solid #e6e6e6;
    overflow-y: scroll;

    &.show {
        margin-right: 0px;
        transition-duration: 300ms;
        transition-property: margin-right;
    }
}

.spinner-wrapper {
    padding: 20px;
}

.footer {
  color: #ccc;
  font-size: $font-size-medium-large;

  a {
    color: #ccc;
    text-decoration: none;
  }

  p {
    padding: 20px 0px;
  }
}

.jodit-status-bar-link {
    display: none;
}

.jodit-container.jodit.jodit_theme_default:not(.jodit-wysiwyg_mode):not(.jodit-source__mode) {
    display: none;
}

.creatable {
    div[class*="-menu"] {
        display: none;
    }
}

.section {
    background: red;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin: 10px 0;

    .section-title {
        font-weight: bold;
    }

    .section-empty-placeholder {
        padding: 10px 0px;
        font-size: $font-size-medium-large;
    }
}

.btn-group {
    .btn {
        margin: 0px;
    }

    .dropdown {
        margin-right: 0px;
        padding-left: 0px;
    }

    .simple-label {
        margin-right: 15px;
    }
}

.btn-toolbar {
    flex-wrap: nowrap;
    display: block;
}

.tooltip {
    top: 0;
}

.alert-dismissible .btn-close {
    padding: 10px;
    font-size: $font-size-medium;
}

.popover-body {
    .actions {
        margin-top: 10px;
        margin-bottom: 0px;
        text-align: end;
    }
}

.overdue {
    color: #da4f49;
}

.flex-button-group {
    display: flex;
}

@media print {
    html {
        // Don't remove the height. Its a hack to work around a print problem.
        height: 99.9%
    }
//     .noprint {
//         display: none !important;
//     }
//
//     .showprint {
//         display: inherit;
//     }
//
//     .content-container {
//         width: auto;
//         height: auto;
//         overflow: visible;
//     }
//
//     .content-box {
//         border: 0px;
//     }
    div.print-no-break {
        break-inside: avoid;
    }
}

.select-container {
    padding: 0px;
    width: 100%;
    height: 34px;
    margin: 0px;

    &.multi {
        height: auto;
        min-height: 34px;
    }

    &.top {
        .select2__menu {
            margin-bottom: 1px;
        }
    }

    .select2__control {
        border: 0px;
        margin-top: -1px;
        box-shadow: none;
        min-height: 36px;
        background: none;
    }

    .select2__control--is-focused {
        border: 0px;
    }

    .select2__menu {
        z-index: 100;
        margin: 0;
        margin-bottom: 0px;

        .select2__menu-list {
            padding: 0px;
        }

        .select2__group {
            padding: 0px;

            .select2__group-heading {
                margin-bottom: 0px;
                background: $gray1-color;
            }
        }
    }

    .select2__multi-value {
        background-color: #f9fafb !important;
        border: 1px solid #D1D5DB;
        border-radius: 5px;
        font-weight: 500;

        .select2__multi-value__label {
            font-size: 12px;
            padding: 1px 1px 1px 6px;
        }

        .select2__multi-value__remove {
            color: #626262;
            opacity: 0.5;
        }

        .select2__multi-value__remove:hover {
            background-color: inherit;
            opacity: 1;
        }
    }

    .select2__menu-list {
        background: $white-color;
        color: $gray4-color;

        .subtitle {
            @extend .body1;
            color: $gray4-color;
        }

        .title {
            @extend .body2;
            color: $gray4-color;
        }
    }

    .select2__multi-value__label {
        .subtitle {
            display: none;
        }
    }

    .select2__option {
        border-bottom: 1px solid $background-color;
    }
    .select2__option--is-disabled {
        background: $white-color;
    }
    .select2__option:not(.select2__option--is-disabled):hover, .select2__option--is-focused:not(.select2__option--is-disabled), .select2__option--is-selected:not(.select2__option--is-disabled) {
        background: $background-color;
        color: $gray4-color;
    }

    .select2__input {
        --tw-ring-color: transparent;
    }
}

.pac-container {
    z-index: 1056;
}

.bordered {
    padding: 5px;
    background: #f0f0f0;
    border: 1px solid #626262;
    border-radius: 7px;
}

.reminders {
    .reminder {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 6px 16px;
        border-radius: 5px;
        border: 1px solid $gray2-color;
        margin-bottom: 10px;

        &.add-new {
            background: none;
            border: 1px dashed #a0a0a0;
        }
    }
}

.integration {
    border: 1px solid #e0e0e0;
    background: white;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    position: relative;

    &.new {
        border: 1px dashed #d0d0d0;
        background: transparent;
    }

    &.disabled {
        opacity: 0.6
    }

//     &.disabled:after {
//         content: url('../public/images/coming-soon.png');
//         position: absolute;
//         left: 25%;
//         top: 40px;
//         opacity: 0.6;
//     }

    .integration-header {
        flex-grow: 0;
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
            margin-left: 10px
        }
    }

    .integration-body {
        flex-grow: 1;
        padding: 6px 0px;
    }

    .integration-footer {
        flex-grow: 0;
    }
}

.integration-logo {
    max-width: 100px;
    max-height: 60px;
}

.flow-content {
    min-height: 400px;
    margin-bottom: 50px;
}

.integration-flow-content {
    margin-top: 30px;
}

.instructions {
    max-width: 100%;
    max-height: 400px;
}

.rule {
    background: $white-color;
    color: $gray4-color;
    padding: 8px 16px;
    border-radius: 4px;
    flex-grow: 1;

    &.alt {
        background: $background-color;
    }

    .form-group {
        margin-bottom: 0px;
    }

    .rule-property {
        border-radius: 5px;
        padding: 3px;
    }

    .rule-value {
        font-style: italic;
    }

    .rule-item {
        border: 0px solid red;
    }

    .rule-leaf-comparator {
        border: 0px solid red;
    }

    .rule-or {
        & > .rule-left {
            padding: 8px 16px;
            border: 0px solid $gray1-color;
            border-radius: 4px;
            background: $white-color;

            &.alt {
                background: $background-color;
            }
        }

        & > .rule-right {
            padding: 8px 16px;
            border: 0px solid $gray1-color;
            border-radius: 4px;
            background: $white-color;

            &.alt {
                background: $background-color;
            }
        }
    }

    .rule-and {
        & > .rule-left {
            padding: 0px;
        }

        & > .rule-right {
            padding: 0px;
        }
    }
}

.item_pricing {
    height: 100%;

    .subtitle {
        font-style: italic;
    }
}

.tooltip-inner {
    border-radius: 8px;
    background: $dark-gray-color-2;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
    max-width: 300px;
}

.tooltip-arrow {
    display: none !important;
}

.btn-primary, .btn-danger {
    .spinner-border {
        border-color: $white-color;
        border-right-color: transparent;
    }
}

.chart-outer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .chart-header {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 10px;
    }

    .chart-footer {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    .legend {
        cursor: pointer;

        .strikethrough {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                top: 6px;
                height: 1px;
                background-color: $gray3-color;
            }
        }
    }

    .legend-dot {
        height: 9px;
        width: 9px;
        min-width: 9px;
        background: $primary-color;
        border-radius: 9px;
    }
}

.ui-datepicker {
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    z-index: 1056 !important;

    td {
        padding: 1px 0;
    }

    .ui-datepicker-header {
        background: transparent;
        border: 0px;
    }

    .ui-state-default {
        border: 0px;
        text-align: center;
        border-radius: 3px;
        background: none;
        border-radius: 20px;
    }

    .ui-datepicker-today {
        .ui-state-highlight {
            background-color: $gray1-color;
            border-color: $gray1-color;
            color: $gray4-color;
        }
    }

    .ui-datepicker-current-day {
        .ui-state-default {
            background-color: $primary-color;
            color: $white-color;
        }
    }

    .ui-datepicker-start-date {
        .ui-state-default {
            background-color: $primary-light-color;
            border-color: $primary-light-color;
            color: $white-color;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .ui-datepicker-end-date {
        .ui-state-default {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white-color;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .ui-datepicker-mid-date {
        .ui-state-default {
            background-color: $gray1-color;
            border-color: $gray1-color;
            color: $gray4-color;
            border-radius: 0px;
        }
    }

    .ui-datepicker-next-hover {
        border: 0px;
        margin-top: 5px;
        top: 0px;
    }
    .ui-datepicker-prev-hover {
        border: 0px;
        margin-top: 5px;
        top: 0px;
    }

    .ui-datepicker-prev {
        margin-top: 5px;
        top: 0px;
        left: 3px;

        span {
            top: 0px;
            left: 0px;
            margin: 0px;
        }
    }

    .ui-datepicker-next {
        margin-top: 5px;
        top: 0px;
        right: 3px;

        span {
            top: 0px;
            left: 0px;
            margin: 0px;
        }
    }

    .ui-datepicker-next.ui-corner-all, .ui-datepicker-prev.ui-corner-all {
        width: 24px;
        height: 24px;
    }
    .ui-icon-circle-triangle-e {
        @extend .chevron-right-icon;
        background-position: 0px !important;
        background-repeat: none;
    }
    .ui-icon-circle-triangle-w {
        @extend .chevron-left-icon;
        background-position: 0px !important;
        background-repeat: none;
    }

    .ui-datepicker-calendar {
        th {
            @extend .body2;
        }
    }
    .ui-state-default {
        @extend .body1;
    }
    .ui-datepicker-today {
        .ui-state-highlight {

        }
    }
}

.daterangepicker {
    font-family: 'Lato', sans-serif;
    color: $gray4-color;

    .ranges {
        li {
            font-size: 14px;
        }
    }
}

.daterangepicker {
    font-family: 'Inter', '-apple-system', 'system-ui', 'Segoe UI';
    color: $dark-gray-color-2;
    border-color: $dropdown-border-color;
    border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03), 0px 2px 2px -1px rgba(10, 13, 18, 0.04);

    .date-day {
        height: 36px !important;
        width: 36px !important;
    }

    .ranges {
        ul {
            padding: 4px 0px;
        }

        li {
            font-size: 14px;
            padding: 8px 16px;
        }

        li.active {
            color: $gray4-color !important;
            background-color: $dropdown-bg-color !important;
        }
    
        li:hover {
            background-color: $dropdown-bg-color !important;
        }
    }
}
.daterangepicker.show-calendar {
    overflow: hidden;

    .row-padding {
        height: 8px;
    }

    .ranges {
        margin: 0px !important;

        ul {
            padding: 0px;
        }
    }
}
.daterangepicker::before, .daterangepicker::after {
    display: none;
}

.request-pay {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    background: $white-color;

    .left-half {
        flex: 1;
        padding: 24px 48px 24px 24px;
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
        background: $background-color;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .right-half {
        border-left: 1px solid $gray1-color;
        flex: 1;
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .payment-details {
        max-width: 400px;
        width: 100%;
    }

    .footer-details {
        max-width: 400px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .plan-details {
        max-width: 400px;
        flex-grow: 1;
        width: 100%;
    }
}

.content-body-full {
    height: 100%;
}

.indicator {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    margin-right: -6px;
}

.small-product-logo {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;

    img {
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.brand-logo {
    height: 35px;
    max-width: 100px;
    display: flex;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.powered-by-icon {
    fill: hsla(0,0%,10%,.4);
}

.payment_input {
    margin-top: 8px;
    min-height: 300px;
}

.template-page-break {
    width: 100%;
    border: 1px dashed $gray2-color;
    display: flex;
    justify-content: center;

    &::after {
        content: "Page Break";
    }
}

.template-preview-box {
    padding: 0px;
    width: fit-content;
}

.metric-pricing-row {
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 10px 5px;
}

.infinite-scroll-component {
    overflow: visible !important;
}

#fr-logo {
    display: none;
}

.fr-view {
    img.fr-dib {
        display: initial;
    }
}

.fr-editor-container .fr-wrapper {
    display: flex;
    justify-content: center;
}

.fr-editor-container .fr-view {
    zoom: 45%;
    transform-origin: top;
    min-width: 948px;
    height: min-content;
    background-color: white;
    flex: 0 0;
}

.fr-editor-container .fr-view > div {
    min-height: min-content;
    background-color: white;
}

@media (min-width: 700px) {
    .fr-editor-container .fr-view {
        zoom: 60%;
    }
}

@media (min-width: 851px) {
    .fr-editor-container .fr-view {
        zoom: 70%;
    }
}

@media (min-width: 1100px) {
    .fr-editor-container .fr-view {
        zoom: 90%;
    }
}

.fr-wrapper.show-placeholder .fr-placeholder {
    display: none;
}

.fr-box.fr-basic {
    border-radius: 4px;

    .fr-toolbar.fr-top {
        border-radius: 4px 4px 0px 0px;
    }
    .fr-wrapper {
        padding: 30px 30px;
        background: $background-color;

        scrollbar-width: thin;
    }
    .fr-second-toolbar {
        min-height: 4px;
        border-radius: 0px 0px 4px 4px;
    }
    .fr-view {
        background: $white-color;

        td.fr-bordered {
            border: 1px solid #888;
        }

        table {
            border-collapse: collapse;
        }
    }
}

.fr-popup {
    z-index: 2000 !important;
}

.contract-preview-container {
    zoom: 45%;
}

@media (min-width: 700px) {
    .contract-preview-container {
        zoom: 60%;
    }
}

@media (min-width: 851px) {
    .contract-preview-container {
        zoom: 70%;
    }
}

@media (min-width: 1100px) {
    .contract-preview-container {
        zoom: 90%;
    }
}

.maple-replace {
    user-modify: read-only;
    cursor: pointer;
}

.maple-variable {
    @extend .body1;
    cursor: pointer;
    padding: 1px 6px;
    border-radius: 4px;
    background: rgba(255, 227, 0, 0.3);
    display: inline-block;
    margin: 2px;
    border: 1px solid rgba(255, 227, 0, 1);

    &.maple-variable-pricing {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        text-align: center;
        position: relative;
    }

    &.maple-variable-initials {
        width: 65px;
        height: 50px;
        text-align: center;

        &:after {
            content: '\f5b7';
            font-family: FontAwesome;
            font-weight: normal;
            font-style: normal;
            margin: 0px 0px 0px 0px;
            text-decoration:none;
        }
    }

    &.maple-variable-signature {
        width: 150px;
        height: 50px;
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

.schedule-wrapper {
    display: flex;
    border: 1px solid $gray1-color;
    flex-direction: column;

    .schedule-top {
        background: $white-color;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        align-items: center;
        text-align: center;
    }

    .schedule-bottom {
        background: $background-color;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        align-items: center;
        text-align: center;
    }
}

.progress-bar {
    background-color: rgba($gray1-color, 0.75) !important;

    &.bg-info {
        background-color: rgba($gray2-color, 0.75) !important;
    }

    &.bg-success {
        background-color: rgba($positive-color, 0.75) !important;
    }

    &.bg-danger {
        background-color: rgba($red-color, 0.75) !important;
    }
}

.goal-progress {
    display: flex;
    flex-direction: row;

    .goal-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 25px;

        &.success {
            background: rgba($positive-color, 0.20);
            color: $positive-color;
        }

        &.danger {
            background: rgba($red-color, 0.20);
            color: rgba($red-color, 0.8);
        }
    }

    .goal-middle {
        flex-grow: 1;
        align-self: center;
        padding: 5px 20px;
    }

    .goal-right {
        align-self: center;
        padding: 15px;
    }
}

.status-image {
    width: 24px;
    height: 24px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.customer-portal-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    width: 100%;
    min-width: 400px;
    max-width: 800px;

    .customer-portal-wrapper-footer {
        padding: 32px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
}

.metrics-comparison-header {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    border-bottom: 1px solid $gray1-color;
    margin-bottom: 5px;
    gap: 4px;
}

.contract-template-preview-wrapper {
    width: 200px;
    height: 250px;
    padding: 0px;
    overflow: hidden;

    .contract-template-preview-content {
        width: 800px;
        height: 1000px;
        -moz-transform: scale(0.25, 0.25);
        -webkit-transform: scale(0.25, 0.25);
        -o-transform: scale(0.25, 0.25);
        -ms-transform: scale(0.25, 0.25);
        transform: scale(0.25, 0.25);
        -moz-transform-origin: top left;
        -webkit-transform-origin: top left;
        -o-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
    }
}

@media (max-width: 1023px) {
    .profile-icon {
        display: none;
    }

    .left-nav {
        margin-top: 48px;
        margin-left: (-$leftnav-uncollapsed-width);
        padding-bottom: 48px;
        position: absolute;
        transition-duration: 300ms;
        transition-property: margin-left;

        &.show {
            margin-left: 0px;
            transition-duration: 300ms;
            transition-property: margin-left;
        }
    }
    // show the header
    .header {
        display: flex;
    }

    .half-flex {
        flex-basis: 100%;
    }

    .request-pay {
        flex-wrap: wrap;
        flex-direction: column;
        height: auto;

        .footer-details {
            margin-top: 10px;
        }

        .plan-details {
            align-items: center;
        }

        .left-half {
            padding: 50px 24px 24px 24px !important;
            align-items: center;
            flex: 1 0 fit-content;
        }

        .right-half {
            padding: 24px !important;
            align-items: center;
            flex: 1 0 fit-content;
        }
    }

    .template-preview-box {
        padding: 20px;
    }
}

@media (max-width: 576px) {
    .profile-icon {
        display: none;
    }

    .content-wrapper {
        padding: 16px 5px;
    }

    .header {
        padding: 6px;
    }

    .left-nav .nav-items {
        margin-top: 16px !important;
    }

    .half-flex {
        flex-basis: 100%;
    }

    .customer-portal-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.daterangepicker .drp-buttons .btn-sm {
    padding: 2px 8px;
}

.discount-select {
    width: 100% !important;
}

.filter-multiselect-group + .filter-multiselect-items {
    margin-left: 24px;
}

.product-pricing-disabled .product-pricing-option-disabled {
    color: $border-color !important;
}