@import '../App.scss';
/* Form Groups
--------------------------------------------------
*/

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
}

button[type="submit"] {
    .spinner-border {
        display: none;
        margin: 0 auto;
        position: initial;
    }
    .spinner-border-sm {
        height: 16px;
        width: 16px;
    }
    .submit-button-content {
        opacity: inherit;
    }
}
button[type="submit"].loading {
    .spinner-border {
        display: inline-block;
        margin: 0 auto;
        position: initial;
        margin-top: 2px;
    }
    .submit-button-content {
        opacity: 0;
    }
}

.form-element {
    @extend .body1;
    color: $gray4-color;
    background-color: transparent;
    height: 36px;
    min-height: 36px;
    padding: 6px 12px 6px 12px;
    border-radius: 4px;
    width: 100%;
    box-shadow: none;

    &::placeholder {
        color: $gray3-color;
    }

    &:disabled, &.disabled {
        opacity: 1;
        background-color: transparent;
    }
}

.form-element-update {
    @extend .form-element;
    padding: 8px;
    border-radius: 8px;

    &::placeholder {
        color: #717680;
    }
}

.form-error-message {
    @extend .body1;
    color: $red-color;
    margin-top: 0px;
}

.form-group, .input-group {
    position: relative;
    border-radius: 2px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &.thin {
        margin-bottom: 0px;
    }

    &.focused {
        .form-input > input {
            --tw-ring-color: transparent;
        }
    }

    .form-input {
        @extend .body1;
        border-radius: 8px;
        min-height: 36px;
        background-color: $white-color;
        border: 1px solid #D5D7DA;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        &.gapless {
            gap: 0px;
        }

        &.transparent {
            background-color: transparent;
        }

        &.borderless {
            border: 0px !important;
        }

        &.textarea {
            display: inline-table;
            min-height: 34px;

            &.required:after {
                top: 7px;
            }
        }

        .form-input-icon {
            margin-left: 8px;
            margin-right: -16px;
            min-width: 24px;
        }

        &.error {
            border: 1px solid $red-color;
            background: $red-light-color;
        }

        &:not(.borderless):has(.form-control):focus-within, &:has(.select2__input:focus), 
        &:not(.borderless):has(.form-select~.form-control):focus-within,
        &:not(.borderless):has(.form-control~.form-select):focus-within {
            border-color: transparent;
            outline: 2px solid $primary-color;
        }

        &.disabled{
            background-color: #FAFAFA !important;
            color: #717680 !important;
        }

        &:has(.select-no-outline):focus-within {
            border: 1px solid #D5D7DA;
            outline: none;
        }
    }

    .form-label + .form-input.textarea.required:after {
        margin-top: 17px;
    }

    .form-label {
        @extend .button-text;
        color: #414651;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .form-label.required:after {
        color: $primary-color;
        content: " *";
        font-family: Inter;
        font-size: 16px;
    }

    .form-control {
        @extend .form-element;
        border: 0px;
        display: flex;
        align-items: center;

        &.inherit {
            width: inherit
        }
    }

    .form-select {
        @extend .form-element;
        border: 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-direction: row;

        .dropdown-toggle {
            padding: 0px 32px 0px 16px;
            border-radius: 0px;
            width: 100%;
            border: 0px;
            justify-content: center;

            &::after {
                display: none;
            }

            &:disabled {
                opacity: 1;
                color: $gray4-color;
            }

            .simple-label {
                @extend .body1;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $gray4-color;
                text-align: start;
            }
        }

        .dropdown-menu {
            .selection-bar {
                padding-left: 12px;
                padding-right: 12px;
                display: flex;
                flex-direction: row;
                gap: 6px;
                align-items: center;
            }
        }

        input[type=checkbox] {
            accent-color: $primary-color;
        }
    }

    .form-select.form-select-remove-arrow {
        background-image: none;
    }

    .form-file-input {
        flex-grow: 1;
        height: 34px;
        margin-top: -5px;
        margin-bottom: -5px;

        &:focus-visible {
            outline: none;
        }

        &::file-selector-button {
            z-index: -1;
            color: $gray4-color;
            padding: 6px 16px;
            margin: 0px 16px -1px -1px;
            border-right: 1px solid $gray1-color;
            background-color: rgba($gray1-color, 0.7);

            &:hover {
                background-color: rgba($gray1-color, 0.7);
                color: $gray4-color;
            }
        }
    }

    .form-switch-label {
        padding-left: 6px;
    }

    .form-switch {
        height: 36px;
        padding: 6px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .form-check-input {
            accent-color: $primary-color;
            margin: 0px;
            height: 24px;
            width: 50px;
            display: inline-block;
            vertical-align: middle;
            background-size: 29px 29px;
            border: 1px solid $gray1-color;
            background-color: rgba($gray1-color, 0.4);
            background-position: -3px center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' width='150' height='150' version='1.1'%3E%3Ccircle r='3' stroke='%23C4C4C4' stroke-width='0.2' fill='rgba(256, 256, 256, 1)'/%3E%3C/svg%3E%0A");
        }

        .form-check-input:hover, .form-check-input:focus, .form-check-input:active {
            box-shadow: none;
        }

        .form-check-input:checked {
            background-color: $primary-color;
            background-size: 29px 29px;
            border: 0px;
            background-position: 24px center;
        }

        .form-check-input:before {
            width: 20px;
        }
    }

    .form-check {
        height: 36px;
        padding: 6px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        input[type=checkbox].form-check-input {
            background-color: transparent;
            border: 1px solid $gray3-color;
            margin: 0px;
            box-shadow: none;
            outline: none;
            border-radius: 2px;

            &:checked {
                background-color: $primary-color;
                border: 0px;
            }

            &:hover, &:active {
                filter: none;
                box-shadow: 0 0 1px 3px $primary-light-color;
            }
        }

        input[type=radio].form-check-input {
            background-color: transparent;
            border: 1px solid $gray3-color;
            margin: 0px;
            box-shadow: none;
            outline: none;

            &:checked {
                background-color: $primary-color;
                border: 0px;
                background-image: none;
            }

            &:hover, &:active {
                filter: none;
                box-shadow: 0 0 1px 3px $primary-light-color;
            }
        }

        .form-check-label {
            @extend .body1;
            color: $gray4-color;
        }
    }

    .form-editor {
        flex-grow: 1;
        min-width: 0px;
    }

    .file-preview {
        display: flex;
        background-color: $gray1-color !important;
        height: 34px;
        width: 36px;
        text-align: center;
        right: 0px;
        margin-top: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        flex-direction: column;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .form-clear {
        height: 34px;
        width: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $gray2-color;

        &.file-clear {
            width: 24px;
        }
    }

    textarea {
        border: 0;
        background: transparent;
        resize: vertical;
        overflow: auto;
    }

    .form-color-label {
        padding: 6px 6px 6px 16px;
        cursor: pointer;
    }

    .form-color {
        display: grid !important;
        height: 36px !important;
        padding: 0px;
        width: 36px;
    }

    .form-color-value-wrapper {
        background-color: $primary-color;
    }

    .form-color-value {
        padding: 0px;
        width: 36px;
        height: 36px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background: repeating-linear-gradient(45deg,
            $background-color, $background-color 5px,
            $gray1-color 5px, $gray1-color 10px);
        cursor: pointer;
    }

    .github-picker {
        right: 0px;
    }
    .sketch-picker {
        position: relative;
        right: 233px !important;
        z-index: 100;
        width: 250px !important;
        top: 1px !important;
    }

  .input-group-text {
    background-color: #f2f2f2;
    width: 1%;
    white-space: nowrap;
    height: 36px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .input-group-text, .input-group-addon, .form-switch1, .form-color {
    display: table-cell;
    color: #a0a0a0;
    font-size: 14px;
    vertical-align: middle;
    border: 0px;
    text-align: center;
    min-width: 36px;

    &.input-group-text {
        min-width: 20px;
    }

    &.divider {
        height: 36px;
        padding: 0px;
        min-width: 1px;
        max-width: 1px;
        background: $gray1-color;
    }

    &.skinny {
        height: inherit;
    }
  }

  .form-switch1 {
    padding: 1px;
    width: 80px;
    height: 56px;
    text-align: end;
  }

  .github-picker {
    position: absolute !important;
    z-index: 10 !important;
    width: 285px !important;

    span {
        margin: 2px !important;
        width: 35px !important;
        height: 35px !important;

        div {
            width: 35px !important;
            height: 35px !important;
        }
    }
  }

  .form-check-input2:hover, .form-check-input2:focus, .form-check-input2:active {
    box-shadow: none;
  }

  .form-check-input2:checked {
    background-color: rgb(72, 176, 247);
    background-size: 45px 45px;
    border: 0px;
    background-position: 15px center;
  }

  .form-check-input2:before {
    width: 20px;
  }

  input {
    border: 0;
  }

  input::placeholder, textarea::placeholder {
    opacity: .4;
  }

  &.required:after {
    color: #f55753;
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 3px;
  }

  &.focused {
    label {
      opacity: 1;
    }
  }

  &.error {
    border-color: #dc212760;
    background: #dc212710;
  }
}

.form-switch .form-check-input, .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' width='150' height='150' version='1.1'%3E%3Ccircle r='3' stroke='%23C4C4C4' stroke-width='0.2' fill='rgba(256, 256, 256, 1)'/%3E%3C/svg%3E%0A");
}

.form-check-input:checked {
    background-color: #48b0f7;
    border-color: #48b0f7;
}

.form-check-inline {
    margin-right: 0px;
}

.form-check {
    padding-top: 1px;
}

.lg-switch {
    input[type="checkbox"] {
        width: 50px;
        height: 30px;
    }
}

.jodit_sticky>.jodit-toolbar__box {
    top: -32px !important;
}

//  Hide arrows in Chrome, Safari, Edge, and Opera
.no-arrows[type="number"] {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

// Hide arrows in Firefox
.no-arrows[type="number"] {
    appearance: none;
    -moz-appearance: none;
}