@import "../App.scss";
@import "./icons.scss";

.modal {
    border: none;
    box-shadow: none;
}

.modal-content {
    margin-top: 70px;
    margin-bottom: 30px;
    border-radius: 12px;
}

.modal-md {
    width: 600px;
    max-width: 600px;
}

.modal-lg {
    width: 900px;
    max-width: 900px;
}

.modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-custom-full {
    width: 96vw;
    max-width: 96vw;
    height: 90vh;
    max-height: 90vh;
}

@media (min-width: 600px) {
    .modal-custom-full {
        width: 90vw;
        max-width: 90vw;
        height: 90vh;
        max-height: 90vh;
    }
}

.modal-header {
    background-color: $white-color;
    color: $dark-gray-color-2;
    border-bottom: 1px solid #E9EAEB;
    z-index: 10;
    padding: 16px 20px;

    .modal-title {
        font-size: 18px;

        i {
            margin-bottom: 3px;
        }
    }

    .btn-close {
        font-size: 18px;
        color: $gray4-color;
        display: grid;

        &:focus, &:focus:active {
            box-shadow: none;
        }
    }
}

.modal-body {
    @extend .body1;
    padding: 16px 20px;
    min-height: 75px;
}

.modal-footer {
    background: white;
    border-top: 1px solid #E9EAEB;
    padding: 12px 20px;

    & > * {
        margin: 0px;
    }
}

.side-modal {
    position: absolute;
    width: 500px;
    height: 100%;
    background: $white-color;
    right: 0px;
    top: 0px;
    z-index: 11;
    margin-right: -500px;
    border-left: 1px solid $gray2-color;
    -webkit-transition: margin-right 0.25s ease-out;
    transition: margin-right 0.25s ease-out;
    display: flex;
    flex-direction: column;

    &.lg {
        width: 700px;
        margin-right: -700px;
    }

    &.show {
        margin-right: 0px;
        -webkit-transition: margin-right 0.25s ease-out;
        transition: margin-right 0.25s ease-out;
    }
}

@media (max-width: 576px) {
    .side-modal {
        max-width: 100%;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

.side-modal-header {
    z-index: 2;
    padding: 14px 24px;
    background: $white-color;
    border-bottom: 1px solid $gray2-color;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;

    .title {
        @extend h4;
        color: $black-color;
    }

    .action {
        cursor: pointer;
    }
}

.side-modal-body {
    @extend .body1;
    z-index: 1;
    padding: 14px 24px;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    position: absolute;
    top: 57px;
    bottom: 65px;
}

.side-modal-footer {
    z-index: 2;
    padding: 14px 24px;
    width: 100%;
    background: $white-color;
    border-top: 1px solid $gray2-color;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 12px;
}

.variables-modal-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0px;
    left: 0px;
    z-index: 11;
}

.variables-modal {
    position: absolute;
    top: 72px;
    left: 190px;
    overflow: scroll;
    border: 1px solid $gray2-color;
    border-radius: 4px;
    background: $background-color;
    padding: 20px;
    z-index: 1000;
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .variable-item-group {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .variable-items {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .variable-item {
        background: white;
        border: 1px solid $gray2-color;
        border-radius: 4px;
        padding: 3px 5px;
        cursor: pointer;
    }
}

.modal-dimmed .modal-dialog {
    filter: brightness(65%);
}